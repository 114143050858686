<template>
  <div class="content profile-content">
    <ProfileNavbar :title="$t('sidebar.my-consultations')"></ProfileNavbar>
   <div class="mob-menu-title">
<!--      <router-link tag="a" to="/profile" class="mob-menu-back">-->
<!--        <img src="images/profile/arrow-back.svg">-->
<!--      </router-link>-->
     {{ $t('sidebar.my-consultations') }}
   </div>
    <div class="clearfix bg-blue">
      <div>
        <div class="my-results-table-wrapper">
          <!-- <div class="mx-auto pt-4 pl-2 pr-2"> -->
          <div class="my-results-table">
            <div class="results-search" style="display:flex;">

              <el-select v-model="filter.time" :placeholder="$t('profile.notes.time-title')">
                <el-option
                    v-for="item in hours"
                    :key="item"
                    :label= "item+':00'"
                    :value="item+':00:00'">
                </el-option>
              </el-select>
                <el-date-picker
                    class="margLeftConsult"
                    v-model="filter.date"
                    type="date"
                    :placeholder="$t('profile.notes.date-title')"
                    format="yyyy.MM.dd"
                    value-format="yyyy.MM.dd"
                />
              <button   @click="filterTable()" class="btn btn-blue margLeftConsult">
                {{ $t('special-schools.search') }}
              </button>
              <button   @click="updateTable()" class="btn btn-blue margLeftConsult">
                {{ $t('references.reset-btn') }}
              </button>



            </div>
            <div class="results-table table-responsive-md">
              <table class="table table-striped">
                <thead>
                <tr>
                  <th scope="col">{{ $t('my-consultations.day') }}</th>
                  <th scope="col">{{ $t('my-consultations.time') }}</th>
                  <th scope="col">{{ $t('my-consultations.psych') }}</th>
                  <th scope="col">{{ $t('my-consultations.reason') }}</th>
                  <th scope="col">{{ $t('my-consultations.recommendations') }}</th>
                  <th>{{ $t('my-consultations.actions') }}</th>

                </tr>
                </thead>
                <tbody>
                <tr v-for="consult in  consultationsArray">
                  <td scope="row" class="result-subject">{{consult.date.split('-').reverse().join('.')}}</td>
                  <td class="result-score">{{consult.time.slice(0, -3)}}</td>
                  <td class="result-score">
                    <div class="recomendations-row">
                      <span  class="text-mobile-consult"> {{consult.psychologist.last_name +" "+ consult.psychologist.first_name +" "+ (consult.psychologist.patronymic!=null?consult.psychologist.patronymic:'')}}</span>
                      <div class="recomendations-text">
                        {{consult.psychologist.last_name +" "+ consult.psychologist.first_name +" "+ consult.psychologist.patronymic}}
                      </div>
                    </div>

                    </td>
                  <td class="result-date">
                    <span  class="text-mobile-consult">{{consult.visit_purpose}}</span>
                    <div class="recomendations-row">
                      <div class="recomendations-text">
                        {{consult.visit_purpose}}
                      </div>
                    </div>
                  </td>
                  <td class="result-recomendations">
                    <span class="text-mobile-consult">{{consult.recommendation}}</span>
                    <div class="recomendations-row">
                      <div class="recomendations-text">
                        {{consult.recommendation}}
                      </div>
                    </div>
                  </td>
                  <td>
                    <button @click="openModalInfo(consult)" class="btn btn-blue" v-b-modal.modal-result>
                      {{ $t('profile.results.view-btn') }}
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="modal-result" class="modal-result" size="lg" hide-footer hide-header centered>
      <div class="modal-result-title text-left">
        {{ $t('profile.results.modal-title') }}
      </div>
      <div class="modal-result-body text-left">
        <div class="d-flex flex-row">
          <div class="d-flex flex-row align-items-center">
            <img width="24" height="24" src="/images/profile/calendar-icon.svg" alt="">
            <div class="pl-3">{{this.modalInfo.date.split('-').reverse().join('.')}}</div>
          </div>
          <div class="d-flex flex-row pl-4 align-items-center">
            <img width="24" height="24" src="/images/profile/time-icon.svg" alt="">
            <div class="pl-3">{{this.modalInfo.time.slice(0, -3)}}</div>
          </div>
        </div>
        <div class="pt-4 pb-3">
          {{ $t('my-consultations.psych') }}
          <span class="font-weight-bold">{{this.modalInfo.psychologist.last_name+' '+ this.modalInfo.psychologist.first_name+' '+(this.modalInfo.psychologist.patronymic!=null?this.modalInfo.psychologist.patronymic:'')}}</span>
        </div>
        <div class="pb-5">
          {{ $t('my-consultations.reason') }}
          <span class="font-weight-bold">{{this.modalInfo.visit_purpose}}</span>
        </div>
        <div class="pb-2 modal-recomendations-title">
          {{ $t('my-consultations.recommendations') }}
        </div>
        <div class="modal-recomendations-body">
          {{this.modalInfo.recommendation}}
        </div>
      </div>
      <button class="btn result-close" @click="$bvModal.hide('modal-result')">{{
          $t('profile.results.btn-close')
        }}
      </button>
    </b-modal>
  </div>


</template>
<script>
import Vue from 'vue';
import ProfileNavbar from "@/components/ProfileNavbar";
import VueMask from "v-mask";
import {mapActions} from "vuex";

export default {
  name: 'Results',
  data () {
    return {
      consultationsArray:'',
      filter:{
        date:'',
        time:'',
      },
      modalInfo:{
        date:'',
        time:'',
        psychologist:{}
      },
      hours:[
        10, 11, 12, 13, 14, 15, 16, 17,
      ]
    }
  },
  components: {
    ProfileNavbar,
  },
  beforeCreate() {
    Vue.use(VueMask);
  },
  mounted() {
    this.updateTable()
  },
  methods: {
    updateTable(){
      this.filter.date=''
      this.filter.time=''
      this.$http.get(window.API_ROOT + '/api/user-requests')
          .then((res) => {
            this.consultationsArray=res.body.data
          })
          .catch((e) => {
            if (e.status === 401){
              this.$router.push('/')
            }
          });
    },
    openModalInfo(data){
      this.modalInfo=data
      this.$bvModal.show('modal-result')
    },
    filterTable(){
      if (this.filter.date==null){
        this.filter.date=''
      }
      if(this.filter.date==false && this.filter.time==false){
        return
      }
      if(this.filter.date==false) {
        this.$http.get(window.API_ROOT + '/api/user-requests?time='+this.filter.time)
            .then((res) => {
              this.consultationsArray = res.body.data
            })
      }
      else if(this.filter.time==false) {
        this.$http.get(window.API_ROOT + '/api/user-requests?date='+this.filter.date.replace('.', '-').replace('.', '-'))
            .then((res) => {
              this.consultationsArray = res.body.data
            })
      }
      else {
        this.$http.get(window.API_ROOT + '/api/user-requests?time='+this.filter.time+'&date='+this.filter.date.replace('.', '-').replace('.', '-'))
            .then((res) => {
              this.consultationsArray = res.body.data
            })
      }
    }
  }
}
</script>
<style scoped>
.margLeftConsult{
  margin-left: 1rem;
}
.text-mobile-consult{
  display:none
}
.recomendations-row {
  justify-content: center;
}

.my-results-table .results-search input.search-input {
  width: 20%;
}

.content .my-results-table {
  width: 100%;
}

.recomendations-row .recomendations-text {
  min-width: 0;
}

.content .my-results-table, .consultations-table {
  margin: 0;
}
.content .my-results-table {
  border: 1px solid transparent;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04), 0px 4px 16px rgba(0, 0, 0, 0.08);
}
.my-results-table {
    max-width: 1120px;
}
@media (max-width: 992px){
  .my-results-table-wrapper {
    padding: 42px 16px 0 16px;
  }
}
@media (max-width: 766px) {
  .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 100%;
  }
  .results-search{
    margin:0;
    padding:0;

  }
  .margLeftConsult{
    margin-left: 0;
    margin-top: 1rem;
  }
  .results-search {
    flex-direction: column;
    width: 100%;
  }
  .text-mobile-consult{
    display:block;
    padding-bottom: 10px;
  }
  .content .my-results-table tbody tr td:nth-child(3) {
    padding-bottom: 7px;
  }
}
</style>